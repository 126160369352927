




































import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/admin/components/PageHeader.vue";
import __ from "@/shared/helpers/__";
import { CompanyRoutes } from "@/shared/router/company";
import Tab from "@/shared/classes/tab";
import RiskMatrixButton from "@/company/components/risk-assessments/RiskMatrixButton.vue";
import { RoleLevel } from "@/shared/modules/role/role.model";
import roleCan from "@/shared/helpers/role-can.helper";

@Component({
  components: { PageHeader, RiskMatrixButton },
  methods: { __, roleCan }
})
export default class RiskAssessmentsIndex extends Vue {
  roleManagerActive: boolean = roleCan([RoleLevel.manager, RoleLevel.leader])
  tabs = [
    new Tab()
      .setTo(CompanyRoutes.riskAssessmentsIndexTabAssigned)
      .setTitle(__('company.views.risk-assessments.index.tabs.assigned')),
    new Tab()
      .setTo(CompanyRoutes.riskAssessmentsIndexTabTemplates)
      .setTitle(__('company.views.risk-assessments.index.tabs.templates'))
      .setAllowedForRoles([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin]),
  ]
}
