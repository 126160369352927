







import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import HseRiskCategory from "@/shared/modules/hse-risk-categories/hse-risk-category.model";
import { GlobalActions } from "@/shared/store/global/global.actions";
import RiskMatrix from "@/company/components/risk-assessments/RiskMatrix.vue";

@Component({
  methods: { __ }
})
export default class RiskMatrixButton extends Vue {
  openRiskMatrixDialog(item: HseRiskCategory): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: RiskMatrix,
      maxWidth: 900,
      persistent: true,
    })
  }
}
