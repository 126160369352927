









































































import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'


@Component({
  methods: { __ },
})
export default class RiskMatrix extends Vue {
}
